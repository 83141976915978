@use './global-styles/colors';
@use './global-styles/breakpoints';

* {
  padding: 0;
  margin: 0;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.025em;
  line-height: 20px;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

textarea {
  resize: vertical;
}

.app-container {
  width: 100%;
  overflow: hidden;
}

.hidden {
  display: none;
}

.pointer {
  cursor: pointer;
}

.p-40 {
  padding: 40px;
}
.p-72 {
  padding: 72px;
}
.p-24 {
  padding: 24px;
}
.p-16 {
  padding: 24px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-48 {
  margin-bottom: 48px;
}
.mt-40 {
  margin-top: 40px;
}
.mb-24 {
  margin-bottom: 24px;
}
.m-72 {
  margin: 72px;
}
.m-48 {
  margin: 48px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.ml-12 {
  margin-left: 12px;
}
.mt-88 {
  margin-top: 88px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-4 {
  margin-right: 4px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mt-0 {
  margin-top: 0;
}
.mt-4 {
  margin-top: 4px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-13 {
  margin-top: 13px;
}
.mt-24 {
  margin-top: 24px;
}

.mt-28 {
  margin-top: 28px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-36 {
  margin-top: 36px;
}
.margin-16 {
  margin: 16px;
}
.margin-4 {
  margin: 4px;
}
.margin-12 {
  margin: 12px;
}
.vh {
  height: 100vh;
}
.vh-50 {
  height: 50vh;
}
.w-25 {
  width: 25%;
}
.font-16 {
  font-size: 16px;
  line-height: 175%;
}
.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

.mw-100 {
  max-width: 100% !important;
}

.auto-btn-width {
  width: auto !important;
  padding: 12px 24px;
}
.mb-8 {
  margin-bottom: 8px;
}
.w-80 {
  width: 80%;
}
.ml-68 {
  margin-left: 68px;
}
.mr-32 {
  margin-right: 32px;
}
.m-32 {
  margin: 32px;
}
.p-32 {
  padding: 32px;
}
.mt-60 {
  margin-top: 60px;
}
.ml-32 {
  margin-left: 32px;
}

.ml-88 {
  margin-left: 88px;
}
.ml-4 {
  margin-left: 4px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-40 {
  margin-bottom: 40px;
}
.ml-24 {
  margin-left: 24px;
}

// Flex classes
.flex-space-around {
  justify-content: space-evenly;
  align-items: center;
}
.flex-start {
  justify-content: flex-start;
}

.flex-space-between {
  justify-content: space-between;
  align-items: center;
}
.scroll {
  overflow: scroll;
}

.gap-32 {
  gap: 32px;
}
.gap-12 {
  gap: 12px;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.align-center {
  text-align: center;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px auto;
  font-size: 0.9em;
  width: 100%;
  min-width: 400px;
}
.styled-table thead tr {
  background-color: colors.$red-delta;
  color: black;
  text-align: left;
}
.styled-table tbody tr {
  border-bottom: 1px solid colors.$light-gray-delta;
  &:hover {
    font-weight: bold;
    color: #009879;
  }
}
.styled-table th,
.styled-table td {
  padding: 12px 15px;
  min-width: 150px;
}

.no-styled-table {
  border-collapse: collapse;
  margin: 25px auto;
  font-size: 0.9em;
  width: 100%;
  min-width: 400px;
}
.no-styled-table thead tr {
  background-color: colors.$red-delta;
  color: black;
  text-align: left;
}
.no-styled-table tbody tr {
  border-bottom: 1px solid colors.$light-gray-delta;
  color: colors.$light-gray-echo;
}
.no-styled-table th,
.no-styled-table td {
  padding: 12px 15px;
  min-width: 150px;
}

.table_scroll_horizontal {
  overflow-x: auto;
  display: block;
}

.dormant-user {
  background-color: colors.$orange-alpha;
}

.incomplete-order {
  background-color: colors.$light-gray-golf;
}
.incomplete-order-3 {
  // background-color: colors.$light-gray-golf;
  background-color: pink;
}

.warning-container {
  background: rgba(242, 163, 45, 0.1);
  border-radius: 8px;
  color: colors.$orange-alpha;
  max-width: 468px;
  padding: 16px 12px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;

  &-full {
    @extend .warning-container;
    max-width: 50%;
    @media (max-width: breakpoints.$breakpoint-large-screen) {
      max-width: 100%;
    }
  }
  &-fit-content {
    @extend .warning-container;
    max-width: fit-content;
    @media (max-width: breakpoints.$breakpoint-large-screen) {
      max-width: 100%;
    }
  }
}
